
import { defineComponent, ref, watch, computed } from "vue";
import shuffle from "lodash/shuffle";

export default defineComponent({
  name: "MediaMultiChoice",
  emits: ["onShowSolutionButton", "showImage", "onAnswer"],

  props: {
    question: {
      type: Object,
      required: true,
    },
    showCorrectAnswers: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const answers = ref<Array<any>>([]);
    const options = ref<Array<any>>([]);

    const getOptionPosition = (option: any, index: number) => {
      return option.position ?? index + 1;
    };

    const getMediaUrl = (optionPosition: number, fullSize: boolean): string => {
      const baseUrl = process.env.VUE_APP_BACKEND_BASE_URL;
      const option = fullSize ? "reducedSize=false" : "reducedSize=true&thumbnail=START";
      return `${baseUrl}/theory-questions/${props.question.id}/${optionPosition}/media/option?${option}`;
    };

    const onAnswerChange = (value: boolean, position: any) => {
      const answer = answers.value.find((a: any) => a.position == position);
      if (answer) {
        answer.correct = answer.value == value;
        showSolutionButton();
      }
    };

    const isAnswerCorrect = computed(() => {
      return answers.value.findIndex((a: any) => !a.correct) === -1;
    });

    const isAnswerIndexCorrectStyle = (answer: any) => {
      return answer ? `visibility: visible; margin-top: 0.6rem;` : `visibility: hidden; margin-top: 0.6rem;`;
    };

    const showSolutionButton = () => {
      emit("onShowSolutionButton");
    };

    const onClick = (value: any) => {
      emit("showImage", value);
    };

    watch(
      () => props.showCorrectAnswers,
      (toggle) => {
        if (toggle) {
          emit("onAnswer", isAnswerCorrect.value);
        }
      },
      { immediate: true, deep: true }
    );

    watch(
      () => props.question,
      (question) => {
        if (question) {
          options.value = [...question.options];
          options.value = shuffle(options.value);
          options.value = options.value.map((option: any) => ({
            ...option,
            value: false,
          }));

          answers.value = options.value.map((option: any) => {
            const answerPositions = question.correctAnswer.split(";");
            const value = answerPositions.indexOf(String(option.position)) > -1;
            return {
              position: option.position,
              value: value,
              correct: !value,
            };
          });
        }
      },
      { immediate: true, deep: false }
    );

    return {
      answers,
      options,
      getOptionPosition,
      getMediaUrl,
      onAnswerChange,
      isAnswerCorrect,
      isAnswerIndexCorrectStyle,
      showSolutionButton,
      onClick,
    };
  },
});
