
import GuestLayout from "@/layout/GuestLayout.vue";
import { defineComponent } from "vue";
import TheoryQuestions from "@/views/Guest/TheoryQuestions.vue";
import GuestTheoryBlock from "@/views/Guest/GuestTheoryBlock.vue";
import TrainingBlock from "@/views/BasicUser/TrainingBlock.vue";
import MobileTheoryBlock from "@/views/Onboarding/MobileTheoryBlock.vue";
import TrainingBlockHeader from "@/views/BasicUser/TrainingBlockHeader.vue";
import UserService from "@/services/UserService";
import FragenBlock from "@/views/BasicUser/FragenBlock.vue";
import SchoolLogo from "@/components/Avatars/SchoolLogo.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Dashboard",
  components: {
    MobileTheoryBlock,
    TrainingBlock,
    GuestTheoryBlock,
    TheoryQuestions,
    GuestLayout,
    TrainingBlockHeader,
    FragenBlock,
    SchoolLogo,
  },
  setup() {
    const user = UserService.getUser();
    const drivingSchoolId = UserService.getDrivingSchoolId();

    return {
      user,
      drivingSchoolId,
    };
  },
});
