
import { Options } from "vue-property-decorator";
import TitleAndAdditionalText from "@/components/Pages/Parts/TitleAndAdditionalText.vue";
import MediaMultiChoice from "@/components/Pages/TheoryQuestions/Parts/MediaMultiChoice.vue";
import MultiChoice from "@/components/Pages/TheoryQuestions/Parts/MultiChoice.vue";
import FreeText from "@/components/Pages/TheoryQuestions/Parts/FreeText.vue";
import QuestionMixin from "@/mixins/TheoryQuestions/QuestionMixin";
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin";

@Options({
  components: { TitleAndAdditionalText, FreeText, MultiChoice, MediaMultiChoice },
})
export default class BaseQuestion extends mixins(QuestionMixin, ModalMixin) {
  private currentImage = "";

  private showImage(value: any, e: PointerEvent) {
    console.log(value);
    this.currentImage = value;
    this.showModal(e, "imageModal");
  }

  private hideImage(e: PointerEvent) {
    this.hideModal(e, "imageModal");
  }

  private onAnswer(correct: boolean): void {
    this.$emit("onAnswer", {
      correct: correct,
    });
  }
}
