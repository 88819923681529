import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "big-checkbox mt-3 mx-1" }
const _hoisted_2 = { class: "theory-question-checkbox d-flex flex-column align-items-center" }
const _hoisted_3 = ["onClick", "src"]
const _hoisted_4 = { class: "mr-2 mt-2 d-flex flex-column align-items-center" }
const _hoisted_5 = { style: {"position":"relative"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: option.position,
      class: "d-flex mx-md-3 m-auto w-auto"
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "pointer",
            onClick: ($event: any) => (_ctx.onClick(_ctx.getMediaUrl(_ctx.getOptionPosition(option, index), true))),
            width: "120px",
            src: _ctx.getMediaUrl(_ctx.getOptionPosition(option, index), false),
            alt: ""
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_b_form_checkbox, {
                modelValue: option.value,
                "onUpdate:modelValue": ($event: any) => ((option.value) = $event),
                disabled: _ctx.showCorrectAnswers,
                onChange: ($event: any) => (_ctx.onAnswerChange($event, option.position))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"]),
              (_ctx.showCorrectAnswers)
                ? (_openBlock(), _createElementBlock("i", {
                    key: 0,
                    style: _normalizeStyle([{"position":"absolute","right":"24px","top":"2px"}, _ctx.isAnswerIndexCorrectStyle(_ctx.answers[index].value)]),
                    class: "fas fa-play text-black mr-2"
                  }, null, 4))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]))
  }), 128))
}