import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "theory-question-checkbox big-checkbox mt-3 align-items-center"
}
const _hoisted_2 = { class: "theory-question-checkbox-answered big-checkbox mt-3 align-items-center" }
const _hoisted_3 = ["title", "data-position"]
const _hoisted_4 = { class: "theory-question-checkbox big-checkbox mt-3 align-items-center" }
const _hoisted_5 = ["title", "data-position"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FscAudioPlayer = _resolveComponent("FscAudioPlayer")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_component_FscAudioPlayer, {
      ref: "audioPlayer",
      question: _ctx.question,
      options: _ctx.options,
      key: _ctx.question.id
    }, null, 8, ["question", "options"])),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option.position,
        class: "d-flex flex-row"
      }, [
        _createVNode(_component_b_col, {
          md: "1",
          cols: "1",
          class: "d-flex justify-content-end"
        }, {
          default: _withCtx(() => [
            (_ctx.showCorrectAnswers)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("i", {
                    class: "fas fa-play text-black",
                    style: _normalizeStyle(_ctx.isAnswerIndexCorrectStyle(_ctx.answers[index].value))
                  }, null, 4)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_b_col, {
          md: "11",
          class: "p-0 pl-1"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_2, [
              _createVNode(_component_b_form_checkbox, {
                modelValue: option.value,
                "onUpdate:modelValue": ($event: any) => ((option.value) = $event),
                disabled: _ctx.showCorrectAnswers
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    ref_for: true,
                    ref: "optionGesture",
                    title: _ctx.$t('text_to_speech.press_and_hold_to_listen'),
                    "data-position": option.position,
                    class: "checkbox-text",
                    style: {"color":"white","cursor":"help"}
                  }, _toDisplayString(option.text), 9, _hoisted_3)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "disabled"])
            ], 512), [
              [_vShow, _ctx.showCorrectAnswers]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              _createVNode(_component_b_form_checkbox, {
                modelValue: option.value,
                "onUpdate:modelValue": ($event: any) => ((option.value) = $event),
                disabled: _ctx.showCorrectAnswers,
                onChange: ($event: any) => (_ctx.onAnswerChange($event, option.position))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    ref_for: true,
                    ref: "optionGesture",
                    title: _ctx.$t('text_to_speech.press_and_hold_to_listen'),
                    "data-position": option.position,
                    class: "checkbox-text",
                    style: {"color":"white","cursor":"help"}
                  }, _toDisplayString(option.text), 9, _hoisted_5)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"])
            ], 512), [
              [_vShow, !_ctx.showCorrectAnswers]
            ])
          ]),
          _: 2
        }, 1024)
      ]))
    }), 128))
  ]))
}